<template>
    <v-footer color="white" absolute>
        <v-row class="footer-content">
            <v-col md="4" cols="12">
                <div class="footer-title">{{ $t("c_common.c_footer.c_contactClkTitle") }}</div>
                <div class="footer-icon">
                    <img src="/img/ic_phone.svg">
                    <a href="tel:+420 234 760 710">{{ $t("c_common.c_footer.c_contactClkNumber") }}</a>
                </div>
                <div class="footer-icon">
                    <img src="/img/ic_mail.svg">
                    <a class="mail" href="mailto:vzdelavani@clkcr.cz">{{ $t("c_common.c_footer.c_contactClkEmail") }}</a>
                </div>
            </v-col>
            <v-col md="4" cols="12">
                <div class="footer-title">{{ $t("c_common.c_footer.c_technicalSupportTitle") }}</div>
                <div class="footer-icon">
                    <img src="/img/ic_mail.svg">
                    <a class="mail">{{ $t("c_common.c_footer.c_technicalSupportEmail") }}</a>
                </div>
            </v-col>
            <v-col md="4" cols="12">
                <small class="copyright">{{ $t("c_common.c_footer.c_copyright") }}</small><br>
                <small>{{ $t("c_common.c_footer.c_description") }}</small> <br>
                <small v-if="appVersion"> {{ `${$t('c_common.c_labels.c_version')}: ${appVersion}` }}</small>
            </v-col>
        </v-row>
        <hr class="mt-10 footer-content">
        <v-row class="footer-content">
            <v-col class="footer-text">
                <a href="https://soubory.vzdelavanilekaru.cz/Sta%C5%88te%20se%20partnerem.pdf" target="_blank">
                    <small>{{ $t("c_common.c_footer.c_becomePartner") }}</small>
                </a>
            </v-col>
            <v-col class="footer-text">
                <a href="https://soubory.vzdelavanilekaru.cz/PODM%C3%8DNKY%20INZERCE%20NA%20INTERNETOV%C3%89M%20PORT%C3%81LU.pdf" target="_blank">
                    <small>{{ $t("c_common.c_footer.c_termsOfAdvertising") }}</small>
                </a>
            </v-col>
            <v-col class="footer-text">
                <a href="https://soubory.vzdelavanilekaru.cz/GDPR.pdf" target="_blank">
                    <small> {{ $t("c_common.c_footer.c_Gdpr") }}</small>
                </a>
            </v-col>
            <v-col class="footer-text">
                <a href="https://soubory.vzdelavanilekaru.cz/Podm%C3%ADnky%20u%C5%BEit%C3%AD.pdf" target="_blank">
                    <small>{{ $t("c_common.c_footer.c_termsOfUse") }}</small>
                </a>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
    export default {
        name: 'AppFooter',
        data() {
            return {
                appVersion: ''
            };
        },
        mounted() {
            this.appVersion = process.env.appVersion;
        }
    };
</script>

<style lang="scss" scoped>
    .v-footer {
        width: 100%;
        background: white;
        display: block;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .copyright {
        white-space: nowrap;
    }

    .footer-title {
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    .footer-icon {
        margin-bottom: 0.5rem;

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        a {
            padding-left: 1rem;
        }

        &:hover {
            .mail {
                text-decoration: underline;
            }
        }
    }

    .footer-text {
        text-align: center;

        small {
            background: color('light-gray');
            padding: 5px 10px;
            border-radius: 1.5rem;
            white-space: nowrap;
        }
    }

    .footer-content{
        max-width: 77rem;
        margin: auto;

        @media screen and (max-width: 320px) {
            flex-direction: column;
        }
    }
</style>